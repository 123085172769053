import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import PropTypes from 'prop-types';


class Error404Page extends Component {

	componentDidMount() {
	}




	render() {

		const content = this.props.data.allContentfulAccueil.edges[0].node;

		return(
		<Layout>
		<SEO title="Oups" description={content.metaDescription} keywords={content.metaKeywords} />
	
		<section className="module-header full-height jarallax bg-dark bg-dark-30 text-left" id="home" data-jarallax>
			<img className="jarallax-img" src={content.picture404.fluid.src} srcSet={content.picture404.fluid.srcSet} sizes={content.picture404.fluid.sizes} alt=""/>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h1 className="h1 m-b-15">Oups</h1>
						<h1 className="h6 m-b-60">Vous semblez en mauvaise posture</h1>
						<p><Link className="btn btn-brand" to="/"><span>Retour à la page d'accueil</span></Link></p>
					</div>
				</div>
			</div>
		</section>

		
	  </Layout>)
	}
}




Error404Page.propTypes = {
	data: PropTypes.object.isRequired,
	
  }

export default Error404Page

export const staticQuery = graphql`
  query {
    allContentfulAccueil (limit:1) {
      edges {
        node {
          id

          picture404 {
			title
			fluid(maxWidth: 1920, resizingBehavior: FILL) {
				sizes
				src
				srcSet
				aspectRatio
			}
		  }


		  metaTitle
		  metaDescription
		  metaKeywords
        }
      }
    }
  }
`